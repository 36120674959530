import React from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import Layout from "../components/Layout"
import logoImage from "../images/logo-stencil_thee-infinite.png"
import heroImage from "../images/keep-ya-mind-sharp_record.png"
import titleImage from "../images/keep-ya-mind-sharp_logo.png"
import titleImageWegetdown from "../images/we-get-down_hero.png"
import spotifyButton from "../images/button_spotify.png"
import appleButton from "../images/button_apple-music.png"
import bandcampButton from "../images/button_bandcamp.png"
import soundcloudButton from "../images/button_soundcloud.png"

export default () => {
  return (
    <Layout>
      <main className="max-w-6xl m-auto">

      <img
        className="w-full max-w-xl mx-auto mb-12 mt-12 z-20"
        src={logoImage}
        alt="thee Infinite"
      />

        <div className="w-full blur p-8 bg-black bg-opacity-30">
          
          <div className="pb-12 border-b border-white">

            
          
          <div>

          <img
                className="w-full max-w-sm mx-auto mb-4 z-20 max-w-[50%]"
                src={titleImageWegetdown}
                alt="We Get Down – out now!"
              />

            <div class="w-full z-10">
              <LiteYouTubeEmbed 
                id="DYqyRGz1Jm4"
                title="thee Infinite – We Get Down"
              />
            </div>

            </div>

          <div>

          <div className="sm:w-6/12 place-items-center mx-auto mt-12">
              {/* <img
                className="w-full mx-auto mb-4 sm:mb-16"
                src={titleImage}
                alt="thee Infinite"
              /> */}

              {/* <img
                className="w-full max-w-sm mx-auto mb-8 sm:hidden"
                src={heroImage}
                alt="Keep Ya Mind Sharp – out now!"
              /> */}

              {/* <iframe
                class="h-12 mb-8 w-full"
                title="thee Infinite – Keep Ya Mind Sharp"
                src="https://bandcamp.com/EmbeddedPlayer/album=3268049059/size=small/bgcol=333333/linkcol=2ebd35/transparent=true/"
                seamless
              >
                <a href="https://theeinfinite.bandcamp.com/album/keep-ya-mind-sharp">
                  Keep Ya Mind Sharp by Thee Infinite
                </a>
              </iframe> */}

              <div class="text-white text-center mb-4">
                <p>Stream:</p>
              </div>
              <div class="grid place-items-center sm:grid-cols-3 gap-4">
                <div>
                  <a
                    href="https://music.apple.com/de/album/we-get-down-feat-fortified-mind-single/1629511567?l=en"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      className="w-full max-w-xxs mx-auto transition-opacity duration-300 ease-out opacity-100 hover:opacity-75"
                      src={appleButton}
                      alt="thee Infinite on Apple Music"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://open.spotify.com/track/2ccjsPqkF4N86XnODcMHrm?si=jvVV7usYQKaHlm-7J0x5uA"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      className="w-full max-w-xxs mx-auto transition-opacity duration-300 ease-out opacity-100 hover:opacity-75"
                      src={spotifyButton}
                      alt="thee Infinite on Spotify"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://soundcloud.com/theeinfinite/we-get-down"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      className="w-full max-w-xxs mx-auto transition-opacity duration-300 ease-out opacity-100 hover:opacity-75"
                      src={soundcloudButton}
                      alt="thee Infinite on Soundcloud"
                    />
                  </a>
                </div>
              </div>

              <div class="text-white text-center mt-8 mb-4">
                <p>Download incl. instrumental + acapella:</p>
              </div>
              <div class="grid place-items-center sm:grid-cols-3 gap-4">
                <div></div>
                <div>
                  <a
                    href="https://theeinfinite.bandcamp.com/album/we-get-down"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      className="w-full max-w-xxs mx-auto transition-opacity duration-300 ease-out opacity-100 hover:opacity-75"
                      src={bandcampButton}
                      alt="Download on Bandcamp"
                    />
                  </a>
                </div>
                <div></div>
              </div>
            </div>

            {/* <div>
              <img
                className="w-full max-w-sm mx-auto hidden sm:block"
                src={heroImage}
                alt="Keep Ya Mind Sharp – out now!"
              />
            </div> */}
          </div>
          </div>

          <div className="grid place-items-center sm:grid-cols-2 gap-4 pt-12">
            <div>
              <img
                className="w-full mx-auto mb-4 sm:mb-16"
                src={titleImage}
                alt="thee Infinite"
              />

              <img
                className="w-full max-w-sm mx-auto mb-8 sm:hidden"
                src={heroImage}
                alt="Keep Ya Mind Sharp – out now!"
              />

              <iframe
                class="h-12 mb-8 w-full"
                title="thee Infinite – Keep Ya Mind Sharp"
                src="https://bandcamp.com/EmbeddedPlayer/album=3268049059/size=small/bgcol=333333/linkcol=2ebd35/transparent=true/"
                seamless
              >
                <a href="https://theeinfinite.bandcamp.com/album/keep-ya-mind-sharp">
                  Keep Ya Mind Sharp by Thee Infinite
                </a>
              </iframe>

              <div class="text-white text-center mb-4">
                <p>Stream:</p>
              </div>
              <div class="grid place-items-center sm:grid-cols-3 gap-4">
                <div>
                  <a
                    href="https://music.apple.com/us/album/keep-ya-mind-sharp-single/1584717416?uo=4&app=itunes"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      className="w-full max-w-xxs mx-auto transition-opacity duration-300 ease-out opacity-100 hover:opacity-75"
                      src={appleButton}
                      alt="thee Infinite on Apple Music"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://open.spotify.com/track/1a0ZxZwd6Ioy75UjPl1tnQ?si=4Ko-d4KRRGyG0a_4VmELSA"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      className="w-full max-w-xxs mx-auto transition-opacity duration-300 ease-out opacity-100 hover:opacity-75"
                      src={spotifyButton}
                      alt="thee Infinite on Spotify"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://soundcloud.com/theeinfinite/keep-ya-mind-sharp"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      className="w-full max-w-xxs mx-auto transition-opacity duration-300 ease-out opacity-100 hover:opacity-75"
                      src={soundcloudButton}
                      alt="thee Infinite on Soundcloud"
                    />
                  </a>
                </div>
              </div>

              <div class="text-white text-center mt-8 mb-4">
                <p>Download incl. instrumental + acapella:</p>
              </div>
              <div class="grid place-items-center sm:grid-cols-3 gap-4">
                <div></div>
                <div>
                  <a
                    href="https://theeinfinite.bandcamp.com/album/keep-ya-mind-sharp"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      className="w-full max-w-xxs mx-auto transition-opacity duration-300 ease-out opacity-100 hover:opacity-75"
                      src={bandcampButton}
                      alt="Download on Bandcamp"
                    />
                  </a>
                </div>
                <div></div>
              </div>
            </div>

            <div>
              <img
                className="w-full max-w-sm mx-auto hidden sm:block"
                src={heroImage}
                alt="Keep Ya Mind Sharp – out now!"
              />
            </div>
          </div>

        

        


        {/* Social links */}
        <div class="border-t border-white mt-16">
            <ul className={"flex justify-center mt-8"}>
              <li>
                <a
                  href="https://www.instagram.com/theeinfinitehiphop"
                  target="_blank"
                  className={
                    "instagram flex justify-center items-center transition-opacity duration-300 ease-out opacity-100 hover:opacity-75"
                  }
                  rel="noopener noreferrer nofollow"
                  aria-label="Instagram"
                >
                  <svg
                    width="36px"
                    height="36px"
                    viewBox="0 0 31 31"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="192"
                        transform="translate(-223.000000, -37.000000)"
                        fill="#FFFFFF"
                      >
                        <path
                          d="M245.448,37.908 C249.655,37.908 253.098,41.35 253.098,45.557 L253.098,59.502 C253.098,63.709 249.655,67.151 245.448,67.151 L231.505,67.151 C227.297,67.151 223.856,63.709 223.856,59.502 L223.856,45.557 C223.856,41.35 227.297,37.908 231.505,37.908 L245.448,37.908 Z M241.899,43.958 L235.054,43.958 C232.287795,43.958 230.02286,46.1510572 229.908528,48.8896888 L229.904,49.107 L229.904,55.952 C229.904,58.7182051 232.098007,60.9821907 234.836687,61.0964738 L235.054,61.101 L241.899,61.101 C244.665205,61.101 246.929191,58.9079428 247.043474,56.1693112 L247.048,55.952 L247.048,49.107 C247.048,46.268 244.738,43.958 241.899,43.958 Z M241.899,45.571 C243.784,45.571 245.329797,47.0530289 245.430755,48.9132586 L245.436,49.107 L245.436,55.952 C245.436,57.837 243.953037,59.3827967 242.092745,59.4837553 L241.899,59.489 L235.054,59.489 C233.169,59.489 231.624138,58.0060367 231.523241,56.1457447 L231.518,55.952 L231.518,49.107 C231.518,47.222 233.000029,45.6771378 234.860259,45.5762414 L235.054,45.571 L241.899,45.571 Z M238.547,48.308 C236.219,48.308 234.325,50.201 234.325,52.53 C234.325,54.858 236.219,56.752 238.547,56.752 C240.875,56.752 242.769,54.858 242.769,52.53 C242.769,50.201 240.875,48.308 238.547,48.308 Z M238.547,49.825 C240.038,49.825 241.251,51.038 241.251,52.53 C241.251,54.021 240.038,55.234 238.547,55.234 C237.056,55.234 235.843,54.021 235.843,52.53 C235.843,51.038 237.056,49.825 238.547,49.825 Z M243.015,47.019 C242.46,47.019 242.01,47.469 242.01,48.024 C242.01,48.579 242.46,49.029 243.015,49.029 C243.57,49.029 244.021,48.579 244.021,48.024 C244.021,47.469 243.57,47.019 243.015,47.019 Z"
                          id="Instagram"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
              <li className={"ml-4"}>
                <a
                  href="https://www.youtube.com/channel/UCza2oFbCHhnhGngogsSHQXw"
                  className={
                    "facebook flex justify-center items-center transition-opacity duration-300 ease-out opacity-100 hover:opacity-75"
                  }
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  title="YouTube"
                  aria-label="YouTube"
                >
                  <svg
                    width="36px"
                    height="36px"
                    viewBox="0 0 31 30"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="192"
                        transform="translate(-223.000000, -80.000000)"
                        fill="#FFFFFF"
                      >
                        <path
                          d="M245.448,80.392 C249.655,80.392 253.098,83.835 253.098,88.042 L253.098,101.986 C253.098,106.193 249.655,109.634 245.448,109.634 L231.504,109.634 C227.297,109.634 223.856,106.193 223.856,101.986 L223.856,88.042 C223.856,83.835 227.297,80.392 231.505,80.392 L245.448,80.392 Z M242.794,89.377 L233.816,89.377 C231.876,89.377 230.305,90.949 230.305,92.887 L230.305,97.065 C230.305,99.005 231.876,100.577 233.816,100.577 L242.794,100.577 C244.733,100.577 246.305,99.005 246.305,97.065 L246.305,92.887 C246.305,90.949 244.733,89.377 242.794,89.377 Z M237.003,92.779 L240.857,94.876 C241.019,94.967 241.189,95.201 241.025,95.29 L236.999,97.281 C236.841,97.367 236.305,97.253 236.305,97.073 L236.305,92.984 C236.305,92.803 236.845,92.688 237.003,92.779 Z"
                          id="Combined-Shape"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
              <li className={"ml-4"}>
                <a
                  href="https://www.twitter.com/InfiniteThee"
                  className={
                    "flex justify-center items-center transition-opacity duration-300 ease-out opacity-100 hover:opacity-75"
                  }
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  aria-label="Twitter"
                >
                  <svg
                    width="36px"
                    height="36px"
                    viewBox="0 0 30 31"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="192"
                        transform="translate(-266.000000, -37.000000)"
                        fill="#FFFFFF"
                      >
                        <path
                          d="M287.933,37.908 C292.14,37.908 295.582,41.35 295.582,45.558 L295.582,59.502 C295.582,63.709 292.14,67.151 287.933,67.151 L273.988,67.151 C269.781,67.151 266.339,63.709 266.339,59.502 L266.339,45.558 C266.339,41.35 269.781,37.908 273.988,37.908 L287.933,37.908 Z M284.108,45.953 C283.638,45.984 283.177,46.116 282.76,46.334 C282.342,46.552 281.97,46.856 281.673,47.222 C281.365,47.601 281.14,48.048 281.024,48.522 C280.911,48.98 280.921,49.433 280.989,49.895 L280.923,49.972 C278.297,49.581 276.12,48.642 274.353,46.628 C274.276,46.54 274.234,46.54 274.172,46.635 C273.399,47.794 273.774,49.656 274.739,50.571 C274.868,50.693 275.001,50.815 275.144,50.926 C275.091,50.937 274.448,50.867 273.875,50.571 L273.753,50.637 C273.746,50.763 273.757,50.878 273.775,51.014 C273.923,52.189 274.736,53.276 275.851,53.699 C275.983,53.755 276.129,53.804 276.272,53.828 C276.018,53.884 275.757,53.924 275.028,53.867 C274.938,53.849 274.903,53.895 274.938,53.982 C275.485,55.473 276.666,55.917 277.551,56.17 C277.669,56.19 277.787,56.19 277.906,56.218 L277.885,56.239 C277.592,56.686 276.574,57.019 276.101,57.185 C275.239,57.486 274.302,57.621 273.391,57.532 C273.245,57.51 273.215,57.512 273.175,57.532 L273.217,57.629 C273.401,57.751 273.59,57.859 273.781,57.964 C274.359,58.27 274.962,58.514 275.593,58.688 C278.848,59.587 282.515,58.926 284.959,56.498 C286.879,54.591 287.552,51.961 287.552,49.327 C287.552,49.224 287.673,49.168 287.745,49.113 C288.245,48.738 288.648,48.289 289.021,47.787 C289.104,47.675 289.105,47.575 289.105,47.533 L289.105,47.52 L289.039,47.52 C288.532,47.749 287.973,47.888 287.427,47.98 C287.686,47.936 288.066,47.467 288.22,47.277 C288.452,46.987 288.646,46.657 288.755,46.301 L288.751,46.217 L288.665,46.224 C288.056,46.551 287.425,46.787 286.753,46.955 L286.621,46.921 L286.454,46.75 C286.172,46.51 285.869,46.321 285.528,46.182 C285.08,45.999 284.59,45.922 284.108,45.953 Z"
                          id="Combined-Shape"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
              <li className={"ml-4"}>
                <a
                  href="https://www.facebook.com/theeinfinitehiphop"
                  className={
                    "facebook flex justify-center items-center transition-opacity duration-300 ease-out opacity-100 hover:opacity-75"
                  }
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  aria-label="Facebook"
                >
                  <svg
                    width="36px"
                    height="36px"
                    viewBox="0 0 30 31"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="192"
                        transform="translate(-181.000000, -37.000000)"
                        fill="#FFFFFF"
                      >
                        <path
                          d="M198.263,67.151 L198.263,57.236 L201.669,57.236 L202.317,53.01 L198.263,53.01 L198.263,50.267 C198.263,49.111 198.829,47.984 200.646,47.984 L202.489,47.984 L202.489,44.385 C202.489,44.385 200.816,44.1 199.216,44.1 C195.876,44.1 193.693,46.124 193.693,49.788 L193.693,53.01 L189.981,53.01 L189.981,57.236 L193.693,57.236 L193.693,67.151 L189.006,67.151 C184.799,67.151 181.357,63.709 181.357,59.501 L181.357,45.557 C181.357,41.35 184.799,37.908 189.006,37.908 L202.95,37.908 C207.157,37.908 210.599,41.35 210.599,45.557 L210.6,59.502 C210.599,63.709 207.157,67.151 202.95,67.151 L198.263,67.151 Z"
                          id="Combined-Shape"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>  
      </main>
    </Layout>
  )
}
